module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.6_babel-eslint@10.1.0_eslint@8.57.0__react-do_e55a538d657a9a97efeb42ce09db1c77/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Distribrewtion","short_name":"Distribrewtion","theme_color":"#FECD14","background_color":"#FFFFFF","icon":"/tmp/build/ad0f9cf4/source/ui/libs/brand/src/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4315a0ae16fb5eefacec773c3435bc62"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby@5.13.6_babel-eslint@10.1.0_eslint@8.57.0__react-dom@18.3.1_react@18.3.1__react@1_4625e65ba8275c24c2ee62767299c05c/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
